<template>
  <div class="sc-message--text" :style="messageColors">
    <p v-text="messageText" />

    <p v-if="data.meta" class='sc-message--meta' :style="{color: messageColors.color}">
      {{data.meta}}
    </p>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    messageColors: {
      type: Object,
      required: true
    },
  },

  computed: {
    messageText() {
      return this.data.text
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
