
import { mapState } from 'vuex';
import TextMessage from './TextMessage.vue';
import FileMessage from './FileMessage.vue';
import EmojiMessage from './EmojiMessage.vue';
import TypingMessage from './TypingMessage.vue';
import SystemMessage from './SystemMessage.vue';
import CommentMessage from './CommentMessage.vue';

interface Message {
  author: string,
  type: string,
  data: object
}

type Colors =
  & {
      sentMessage: {
        text: string,
        bg: string
      }
    }
  & {
      receivedMessage: {
        text: string,
        bg: string
      }
    }
  & {
      systemMessage: {
        text: string,
        bg: string
      }
    }
  & {
      commentMessage: {
        text: string,
        bg: string
      }
    }


export default {
  data() {
    return {};
  },

  components: {
    TextMessage,
    FileMessage,
    EmojiMessage,
    TypingMessage,
    SystemMessage,
    CommentMessage
  },

  props: {
    profile: {
      type: Object,
    },
    message: {
      type: Object as () => Message,
      required: true,
    },
    chatImageUrl: {
      type: String,
      default: '',
    },
    colors: {
      type: Object as () => Colors,
      required: true,
    },
    authorName: {
      type: String,
    },
    messageStyling: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapState('account', ['id']),

    getClassContent() {
      return {
        sent: this.message.author === 'me',
        received: this.message.author !== 'me' && this.message.type !== 'system',
        system: this.message.type === 'system'
      }
    }
  },

  methods: {
    sentColorsStyle() {
      return {
        color: this.colors.sentMessage.text,
        backgroundColor: this.colors.sentMessage.bg,
      };
    },

    receivedColorsStyle() {
      return {
        color: this.colors.receivedMessage.text,
        backgroundColor: this.colors.receivedMessage.bg,
      };
    },

    determineMessageColors() {
      return this.message.author === 'me' ? this.sentColorsStyle() : this.receivedColorsStyle();
    },

    systemColorsStyle() {
      return {
        color: this.colors.systemMessage.text,
        backgroundColor: this.colors.systemMessage.bg,
      };
    },

    commentColorsStyle() {
      return {
        color: this.colors.commentMessage.text,
        backgroundColor: this.colors.commentMessage.bg,
      };
    },
  },
};
