<template>
  <div class='sc-message-list' ref='scrollList' :style='{backgroundColor: colors.messageList.bg}'>
    <div class='close-button-container'>
      <v-btn
        @click='close'
        fab
        color='white'
        depressed
        class='indigo--text ml-auto mr-0'
      >
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </div>

    <Message
      v-for='(message, idx) in messages'
      :message='message' :chatImageUrl='chatImageUrl(message.author)'
      :authorName='authorName(message.author)'
      :profile='getProfile(message.author)'
      :key='idx'
      :colors='colors'
      :messageStyling='messageStyling'
      :disabled='disabled'
      @deleteComment="(e) => $emit('deleteComment', e)"
    />
  </div>
</template>
<script>
import Message from './Message.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    Message,
  },

  props: {
    participants: {
      type: Array,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true,
    },
    messageStyling: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    _scrollDown() {
      this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight;
    },

    shouldScrollToBottom() {
      return this.alwaysScrollToBottom || (this.$refs.scrollList.scrollTop > this.$refs.scrollList.scrollHeight - 600);
    },

    getProfile(author) {
      if (author === 'me') {
        return {
          full_name: this.full_name,
          initials: this.initials,
          id: this.id,
        };
      }

      const profile = this.participants.find(profile => profile.id === author);
      // A profile may not be found for system messages or messages by 'me'
      return profile || { imageUrl: '', name: '', initials: this.initials, id: author };
    },

    chatImageUrl(author) {
      return this.getProfile(author).imageUrl;
    },

    authorName(author) {
      return this.getProfile(author).name;
    },

    close() {
      this.$emit('close');
    },
  },

  computed: {
    ...mapState('account', ['full_name', 'id']),
    ...mapGetters('account', ['initials']),
  },

  mounted() {
    this._scrollDown();
  },

  updated() {
    if (this.shouldScrollToBottom())
      this.$nextTick(this._scrollDown());
  },
};
</script>

<style scoped>
.sc-message-list {
  height: 100%;
  overflow-y: auto;
  background-size: 100%;
  padding: 0 8px 8px 8px;
}

.close-button-container {
  position: sticky;
  display: flex;
  top: 0;
  z-index: 1;
}

@media (max-width: 960px) {
  .close-button-container {
    padding-top: 12px;
  }
}
</style>
