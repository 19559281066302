<template>
  <div class='sc-comment' :style='messageColors'>
    <v-layout row align-center>
      <v-flex class='sc-comment--text'>
        <p style='margin: 0'> {{ data.text }} </p>
      </v-flex>

      <v-flex>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon v-on='on' :disabled='disabled'>
              <v-icon small color="error" @click="deleteComment">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>

          <span>
            {{$t('Delete')}}
          </span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    messageColors: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    deleteComment() {
      this.$emit('deleteComment', this.data.id);
    }
  }
};
</script>

<style scoped>
.sc-comment {
  padding: 8px 0px 8px 20px;
  border-radius: 6px;
}

.sc-comment--text {
  font-size: 14px;
  line-height: 1.2;
  word-wrap: anywhere;
}
</style>
